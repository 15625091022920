.sidebar::-webkit-scrollbar {
  display: none;
}
.kingLead {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 2.13rem;
  font-weight: 600;
  font-family: inherit;
}
.icons8RiequilibrareIlPortaf {
  position: relative;
  width: 2.19rem;
  height: 2.19rem;
  flex-shrink: 0;
  object-fit: cover;
}
.icons8RiequilibrareIlPortafParent {
  border-radius: var(--br-sm);
  background-color: var(--color-gray-200);
  color: var(--color-black);
  cursor: pointer;
 
}
.icons8RiequilibrareIlPortafGroup,
.icons8RiequilibrareIlPortafParent {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-md);
  cursor: pointer;
  padding: 10px;
}
.icons8RiequilibrareIlPortafGroup:hover {
  background-color: #f7f7f77e;
  cursor: pointer;
  border-radius: 20px;
}
.frameChild {
  position: relative;
  width: 13.56rem;
  height: 0.06rem;
  flex-shrink: 0;
  object-fit: cover;
}
.frameParent,
.sidebar {
  align-self: stretch;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: flex-start;
}
.frameParent {
  height: 100vh;
  padding: var(--padding-xs);
  align-items: flex-start;
  gap: var(--gap-lg);
  font-size: var(--font-size-base);
}
.sidebar {
  background: #28b0b0;
  width: 16.13rem;
  overflow: scroll;
  padding: var(--padding-xl) var(--padding-xs);
  align-items: center;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--color-white);
  font-family: var(--font-poppins);
  position: relative;

}
@media screen and (max-width: 768px) {
  .kingLead {
    font-size: var(--font-size-lg);
  }
}
@media screen and (max-width: 428px) {
  .sidebar {
    display: none;
  }
}
