.king1Icon {
  position: relative;
  width: 8.94rem;
  height: 8.94rem;
  flex-shrink: 0;
  object-fit: cover;
}
.kingLead {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 2.13rem;
  font-weight: 600;
  font-family: inherit;
  cursor: pointer;
}
.frameChild {
  border: 2px solid rgba(139, 139, 139, 0.49);
  font-family: Poppins;
  font-size: 1rem;
  background-color: transparent;
  border-radius: 10px;
  box-sizing: border-box;
  width: 14.63rem;
  height: 3.25rem;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 0.63rem;
  align-items: flex-start;
  justify-content: center;
}
.text {
  margin: 0;
  position: absolute;
  top: 0.75rem;
  left: 4.03rem;
  font-size: 1.88rem;
  line-height: 1.38rem;
  font-weight: 500;
  font-family: Roboto;
  color: #fff;
  text-align: left;
}
.textWrapper {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: #28b0b0;
  position: relative;
  border-radius: 10px;
  width: 12.81rem;
  height: 2.81rem;
  flex-shrink: 0;
  overflow: hidden;
}
.textWrapper:hover{
  cursor: pointer;
  background-color: #040e31;

}
.king1Parent {
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.login {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.king1Parent {
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: 2.75rem 8.13rem;
  gap: 1.88rem;
}
.login {
  position: relative;
  background-color:#28b0b0;;
  width: 100%;
  padding: 6.88rem 12.5rem;
  box-sizing: border-box;
  text-align: left;
  font-size: 2rem;
  color: #434343;
  font-family: Poppins;
  height: 100vh;
}
@media screen and (max-width: 768px) {
  .kingLead {
    font-size: 1.13rem;
  }
}
@media screen and (max-width: 428px) {
  .frameChild {
    height: 3.13rem;
    flex-direction: column;
  }
  .king1Parent {
    padding: 2.5rem 3.75rem;
    box-sizing: border-box;
  }
}
