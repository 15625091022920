.modificaDati,
.testo {
  position: relative;
  line-height: 2.13rem;
}
.modificaDati {
  font-size: var(--font-size-2xl);
  font-weight: 600;
  color: var(--color-darkslategray);
}
.testo {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}
.inputsection,
.inputsectionChild {
  overflow: auto;
  display: flex;
  padding: 0 var(--padding-xs);
  justify-content: flex-start;
}
.inputsectionChild {
  border: 2px solid  rgba(226, 226, 226, 0.85);;
  font-family: var(--font-poppins);
  font-size: var(--font-size-base);
  background-color: transparent;
  border-radius: var(--br-sm);
  box-sizing: border-box;
  width: 14.63rem;
  height: 3.25rem;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
}
.inputsectionChild2 {
 
  font-family: var(--font-poppins);
  font-size: var(--font-size-base);
  background-color: transparent;
  border-radius: var(--br-sm);
  box-sizing: border-box;
  width: 30.63rem;
  height: 3.25rem;
  flex-shrink: 0;

}
.inputsection {
  flex-direction: row;
  width: 60vw;
  align-items: center;
  justify-content: center;
  gap: var(--gap-md);

}
.text {
  margin: 0;
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 1.38rem;
  font-weight: 700;
  font-family: var(--font-roboto);
  color: var(--color-white);
  text-align: left;
}
.textWrapper {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs);
  background-color: var(--color-crimson);
  border-radius: var(--br-sm);
}
.textWrapper:hover {
  background-color: #7a0710;
}
.frameParent,
.popupdata,
.textContainer,
.textWrapper {
  overflow:auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.textContainer {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs);
  background-color: var(--color-midnightblue);
  border-radius: var(--br-sm);
}
.frameParent,
.popupdata {
  gap: var(--gap-sm);
}
.frameParent
{
 margin-top: 5vh;
}
.popupdata {
  position: relative;
  border-radius: var(--br-sm);
  background-color: var(--color-white);
  flex-direction: column;
  padding: var(--padding-sm) var(--padding-lg);
  box-sizing: border-box;
  align-items: center;
  min-width: 70vw ;
  
  overflow-x: auto;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-dimgray-100);
  font-family: var(--font-poppins);
}
.frameTextarea {
  border: 1px solid var(--color-gray-100);
  background-color: transparent;
  font-family: var(--font-poppins);
  font-size: var(--font-size-base);
  border-radius: var(--br-sm);
  box-sizing: border-box;
  width: 30.00rem;
  height: 10.00rem;
  flex-shrink: 0;
  padding: 0 var(--padding-xs);
  justify-content: flex-start;
}
@media screen and (max-width: 768px) {
  .modificaDati,
  .testo {
    font-size: var(--font-size-lg);
  }
  .frameTextarea {
   
    width: 80vw;
    height: 10.00rem;

  }
  .inputsectionChild2 {
 
    font-family: var(--font-poppins);
    font-size: var(--font-size-base);
    background-color: transparent;
    border-radius: var(--br-sm);
    box-sizing: border-box;
    width: 80vw;
    height: 3.25rem;
    flex-shrink: 0;
  
  }
}
@media screen and (max-width: 428px) {
  .inputsectionChild {
    height: 3.13rem;
    flex-direction: column;
  }
  .inputsection {
    height: auto;
    flex-direction: column;
    width: 80vh;
  }
}
