.email,
.nome {
  margin: 0;
  position: relative;
  line-height: 2.13rem;
  font-weight: 500;
  font-family: inherit;
}
.nome {
  font-size: inherit;
}
.email {
  font-size: 0.88rem;
  color: #545454;
}
.icons8HomePage601,
.popovermenuChild {
  position: relative;
  flex-shrink: 0;
  object-fit: cover;
}
.popovermenuChild {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 0.06rem;
}
.icons8HomePage601 {
  width: 2rem;
  height: 2rem;
}
.home {
  margin: 0;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 2.13rem;
  font-weight: 500;
  font-family: var(--font-poppins);
  color: var(--color-black);
  text-align: left;
}
.homebutton {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-sm);
  background-color: transparent;
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.homebutton:hover {
  background-color: var(--color-whitesmoke);
  cursor: pointer;
}
.homebutton,
.logoutbtn,
.popovermenu {
  overflow: hidden;
  display: flex;
  align-items: center;
}
.logoutbtn {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-sm);
  background-color: #e7e7e7;
  align-self: stretch;
  border-radius: 20px;
  
  flex-direction: row;
  justify-content: center;
}
.logoutbtn:hover {
  background-color: #dbdada;
}
.popovermenu {
  position: absolute;
  top: -15px;
  right: 20px;
  border-radius: var(--br-sm);
  border-top-right-radius: 0px !important;
  background-color: #ffffff;
  border: #d3d3d3 1px solid;
  width: 13.75rem;
  height: 15.31rem;
  flex-direction: column;
  padding: var(--padding-xs);
  box-sizing: border-box;
  justify-content: flex-start;
  gap: 0.06rem;
  margin-top: 12vh;
  margin-right: 4vw;
  max-width: 100%;
  max-height: 100%;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-poppins);
  z-index: 100;
}
