@keyframes slide-in-left {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.analisiLead,
.totali {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 2.13rem;
  font-weight: 600;
  font-family: inherit;
}
.totali {
  font-weight: 400;
}
.icons8Ventiquattrore901 {
  position: relative;
  width: 3.31rem;
  height: 3.31rem;
  flex-shrink: 0;
  object-fit: cover;
}
.icons8Ventiquattrore901Parent {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--font-size-4xl);
  color: #477af2;
}
.icons8Ventiquattrore901Group,
.icons8Ventiquattrore901Parent,
.totaliParent {
  overflow: hidden;
  display: flex;
  gap: var(--gap-md);
}
.totaliParent {
  border-radius: var(--br-sm);
  background-color: var(--color-white);
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  flex-direction: column;
  padding: var(--padding-sm) var(--padding-2xl) var(--padding-sm)
    var(--padding-sm);
  align-items: flex-start;
  justify-content: center;
}
.icons8Ventiquattrore901Group {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--font-size-4xl);
  color: #4caf50;
}
.confermateParent {
  border-radius: var(--br-sm);
  background-color: var(--color-white);
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-sm) var(--padding-2xl) var(--padding-sm)
    var(--padding-sm);
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-md);
}
.icons8Ventiquattrore901Container {
  justify-content: flex-start;
  gap: var(--gap-md);
  font-size: var(--font-size-4xl);
  color: #e52030;
}
.frameDiv,

.icons8Ventiquattrore901Container {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.frameDiv {
  justify-content: flex-start;
  gap: var(--gap-md);
  font-size: var(--font-size-4xl);
  color: #fcc419;
}
.frameParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  padding: var(--padding-sm) 2rem;
  justify-content: center;
  gap: 2.5rem;
  font-size: var(--font-size-base);
}
.containerdatiChild {
  border: 0;
  background-color: var(--color-white);
  font-weight: 600;
  font-family: var(--font-poppins);
  font-size: var(--font-size-2xl);
  width: 42.5rem;
  height: 21.94rem;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.containerdati,
.main {
  align-self: stretch;
  flex: 1;
  overflow: scroll;
  align-items: flex-start;
}
.containerdati {
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs);
  justify-content: flex-start;
}
.main {
  background-color: #f6f8fc;
  flex-direction: column;
  padding: var(--padding-xs) var(--padding-lg);
  gap: var(--gap-sm);
}
.home,
.main,
.mainui {
  display: flex;
  justify-content: flex-start;
}
.mainui {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-2xs);
}
.home {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  overflow: hidden;
  max-height: 100vh;
  flex-direction: row;
  align-items: flex-start;
  opacity: 0;
  text-align: left;
  font-size: var(--font-size-2xl);
  color: var(--color-darkslategray);
  font-family: var(--font-poppins);
}
.home.animate {
  animation: 0.25s ease 0s 1 normal forwards slide-in-left;
}
.textWrapper {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: #28b0b0;
  border-radius: var(--br-sm);
  width: 12.81rem;
  height: 2.81rem;
  flex-shrink: 0;
  justify-content: center;
  display: flex;
  align-items: center;
  color: white;
  font-size: 1.2rem;
  line-height: 1.38rem;
  font-weight: 700;
  font-family: var(--font-roboto);
  text-align: left;
}
.text {
  margin: 0;
  position: absolute;
  top: 0.81rem;
  left: 2.03rem;
  font-size: 1.88rem;
  line-height: 1.38rem;
  font-weight: 700;
  font-family: var(--font-roboto);
  color: var(--color-white);
  text-align: left;
}
@media screen and (max-width: 768px) {
  .analisiLead,
  .totali {
    font-size: var(--font-size-lg);
  }
}
@media screen and (max-width: 428px) {
  .home{
    overflow: hidden;
  }
  .main{
    padding: 10px;
  }
  .mainui{
    overflow: hidden;
    max-height: 90vh;
    padding: 0;
  }
  .totaliParent {
    flex: 1;
    height: auto;
  }
  .frameParent {
    flex-direction: column;
    
    box-sizing: border-box;
  }
}
