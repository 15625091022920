.king1Icon {
  position: relative;
  width: 8.94rem;
  height: 8.94rem;
  flex-shrink: 0;
  object-fit: cover;
}
.accountBloccato {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 2.13rem;
  font-weight: 600;
  font-family: inherit;
  cursor: pointer;
}
.accountbloccato,
.king1Parent {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.king1Parent {
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: 3.75rem 8.13rem;
  gap: 1.88rem;
}
.accountbloccato {
  position: relative;
  background-color: #faf9f9;
  width: 100%;
  padding: 6.88rem 12.5rem;
  box-sizing: border-box;
  text-align: left;
  font-size: 2rem;
  color: #434343;
  font-family: Poppins;
}
@media screen and (max-width: 768px) {
  .accountBloccato {
    font-size: 1.13rem;
  }
}
@media screen and (max-width: 428px) {
  .king1Parent {
    padding: 2.5rem 3.75rem;
    box-sizing: border-box;
  }
}
