@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");

body {
  margin: 0;
  line-height: normal;
}
/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #b8b8b8;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #c4c4c4;
}
/* custom scrollbar */
:root {
  /* fonts */
  --font-roboto: Roboto;
  --font-poppins: Poppins;

  /* font sizes */
  --font-size-base: 1rem;
  --font-size-lg: 1.13rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.38rem;
  --font-size-3xl: 2rem;
  --font-size-4xl: 2.5rem;

  /* Colors */
  --color-white: #fff;
  --color-midnightblue: #28b0b0;
  --color-crimson: #e52030;
  --color-gray-100: rgba(139, 139, 139, 0.49);
  --color-gray-200: rgba(255, 255, 255, 0.85);
  --color-dimgray-100: #5c5c5c;
  --color-darkslategray: #434343;
  --color-black: #000;
  --color-whitesmoke: #f5f5f5;
  




  /* Gaps */
  --gap-2xs: 0.13rem;
  --gap-xs: 0.25rem;
  --gap-sm: 0.63rem;
  --gap-md: 1.25rem;
  --gap-lg: 1.88rem;
  --gap-xl: 3.13rem;

  /* Paddings */
  --padding-2xs: 0.31rem;
  --padding-xs: 0.63rem;
  --padding-sm: 1.25rem;
  --padding-md: 2.38rem;
  --padding-lg: 2.5rem;
  --padding-xl: 3.13rem;
  --padding-2xl: 4.38rem;

  /* border radiuses */
  --br-sm: 10px;
  --br-md: 90px;
}
