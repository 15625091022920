.nomelogin,
.overview {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 2.13rem;
  font-family: inherit;
}
.overview {
  font-weight: 600;

}
.nomelogin {
  font-weight: 500;
}
.icona {
  position: relative;
  border-radius: var(--br-md);
  width: 4.25rem;
  height: 4.25rem;
  flex-shrink: 0;
  object-fit: cover;
  cursor: pointer;
}

.displaylogin,
.topmenu {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.displaylogin {
  overflow: hidden;
  padding: var(--padding-xs);
  justify-content: flex-start;
  gap: var(--gap-sm);
  font-size: var(--font-size-xl);
}
.topmenu {
  align-self: stretch;
  background-color: var(--color-white);
  overflow: hidden;
  padding: var(--padding-sm) var(--padding-md) var(--padding-xs);
  justify-content: space-between;
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--color-darkslategray);
  font-family: var(--font-poppins);
  min-height: 8vh;

}
.ham{
  display: none;
}
@media screen and (max-width: 768px) {
  .nomelogin,
  .overview {
    font-size: var(--font-size-lg);
  }
  .topmenu {
    height: 8vh;
  }
  .ham{
    display: flex;
  }
  .topmenu{
    padding: 20px;
  }
}
@media screen and (max-width: 428px) {
  .topmenu {
    height: 8vh;
  }
  .nomelogin{
    display: none;
  }
}
